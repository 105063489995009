<template>
  <div class="products-card">
    <div class="card__head">
      <swiper :ref="ref" :options="swiperOption" @slideChange="handleSlide">
        <swiper-slide
          v-for="image in groupBuying.product.images"
          :key="image.id"
        >
          <img :src="image.pic_url" />
        </swiper-slide>
      </swiper>
      <div class="page">
        {{ activeIndex }}/{{ groupBuying.product.images.length }}
      </div>

      <template v-if="groupBuying.product.images.length > 1">
        <div
          class="product-swiper-button-prev"
          :class="'product-swiper-button-prev_' + groupBuying.id"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="17"
            height="17"
          >
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M109.3 288L480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288z"
            />
          </svg>
        </div>
        <div
          class="product-swiper-button-next"
          :class="'product-swiper-button-next_' + groupBuying.id"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="17"
            height="17"
          >
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
            />
          </svg>
        </div>
      </template>
    </div>
    <div class="card__body">
      <div class="card__title">{{ groupBuying.title }}</div>
      <div class="card__desc" style="white-space: pre-line">
        {{ groupBuying.description }}
      </div>
      <div class="card__split-line my-3"></div>
      <div class="card__title">{{ groupBuying.product.title }}</div>
      <div class="card__desc" style="white-space: pre-line">
        {{ groupBuying.product.description }}
      </div>
      <!-- <div class="s-mb-10" v-else> -->
      <!--   <Toast v-if="status === 'success'" variant="success" -->
      <!--     >團購順利成團！</Toast -->
      <!--   > -->
      <!--   <Toast v-if="status === 'disabled'" variant="error">優惠券已失效</Toast> -->
      <!--   <Toast v-if="status === 'permission_denied'" variant="error" -->
      <!--     >您未加入此團購，無法取得優惠券</Toast -->
      <!--   > -->
      <!-- </div> -->
    </div>
    <div class="card__footer">
      <div class="card__line"></div>
      <template>
        <div class="card__row" v-if="groupBuying.show_total_amount">
          <div class="card__row--name">目前團購數量</div>
          <div>
            <span class="card__row--volume">{{
              groupBuying.current_quantity + groupBuying.adjust_quantity
            }}</span
            >組
          </div>
        </div>
        <div class="card__row">
          <div>集氣截止日期</div>
          <div>{{ endDate }}</div>
        </div>
        <div class="card__actions">
          <button
            class="s-btn s-liff-theme-btn-outline s-btn-sm"
            type="button"
            @click="shareGroupBuying"
            :disabled="isExpired || isSoldOut"
          >
            分享好友
          </button>
          <button
            class="s-btn s-liff-theme-btn s-btn-sm"
            type="button"
            @click="handleTogroupBuying"
            :disabled="!canSubmit || isExpired || isSoldOut"
          >
            {{ submitMessage }}
          </button>
        </div>
      </template>
      <!-- <div v-else-if="status === 'permission_denied'"> -->
      <!--   <button class="s-btn s-btn-outline-primary s-btn-sm" type="button"> -->
      <!--     瀏覽其他團購商品 -->
      <!--   </button> -->
      <!-- </div> -->
      <!-- <div class="barcode" v-else> -->
      <!--   <div class="barcode__title">優惠券</div> -->
      <!--   <img src="@/components/Page/Liff/groupBuying/Products/barcode.png" /> -->
      <!--   <div class="barcode__id">2522022000001</div> -->
      <!--   <div class="barcode__stamp" v-if="status === 'disabled'"> -->
      <!--     <img -->
      <!--       src="@/components/Page/Liff/groupBuying/Products/stamp-disabled.png" -->
      <!--     /> -->
      <!--   </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { format } from "date-fns";
import CoreGroupBuyingApi from "@/apis/liff/CoreGroupBuying";
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";
import liff from "@line/liff";

// import Toast from "@/components/Page/Liff/Shared/Toast";

export default {
  props: {
    groupBuying: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    // Toast,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      ref: "swiper_" + this.groupBuying.id,
      activeIndex: 1,
      swiperOption: {
        loop: this.groupBuying.product.images.length > 1,
        containerModifierClass: "product-swiper-",
        navigation: {
          nextEl: ".product-swiper-button-next_" + this.groupBuying.id,
          prevEl: ".product-swiper-button-prev_" + this.groupBuying.id,
        },
      },
      shareMessage: null,
    };
  },
  computed: {
    swiper() {
      return this.$refs[this.ref].$swiper;
    },
    endDate() {
      return format(new Date(this.groupBuying.end_at), "yyyy-MM-dd");
    },
    canSubmit() {
      return (
        this.groupBuying.can_order_again ||
        this.groupBuying.groupbuying_orders_count === 0
      );
    },
    submitMessage() {
      if (this.isLoading) {
        return "處理中"
      }

      if (this.isExpired) {
        return "團購截止"
      }

      if (this.isSoldOut && !this.canSubmit) {
        return "您已登記";
      }

      if (this.isSoldOut && this.canSubmit) {
        return "數量達上限";
      }

      if (this.canSubmit) {
        return "我要團購";
      }

      return "您已登記";
    },
    isExpired() {
      const now = new Date();
      const endAt = new Date(this.groupBuying.end_at);
      console.log(now, endAt);
      return now > endAt;
    },
    isSoldOut() {
      if (this.groupBuying.max_quantity === 0) return false
      return this.groupBuying.current_quantity >= this.groupBuying.max_quantity
    },
  },
  mounted() {
    this.isMounted = true;
    // this.getShareMessage()
  },
  methods: {

    // 不知道為什麼 liff shareTargetPicker 前面有 async await 時會失敗，所以取得分享訊息額外拉出來
    async getShareMessage() {
      this.shareMessage = await CoreGroupBuyingApi.getInstance().getShareMessage(this.groupBuying)
    },
    async shareGroupBuying() {
      console.log('path org', this.$route.params.orgCode)

      const orgCode = this.$route.params.orgCode;
      const groupbuyingId = this.groupBuying.id;

      if (!groupbuyingId) {
        alert('此分享名片網址沒有團購資料，請確認網址是否正確');
      }

      const checker = new LiffChecker(orgCode);
      await checker.initLiff();

      // Get Template Data
      try {
        let url = process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/groupbuying/share-groupbuying-template?id=${groupbuyingId}`;

        let response = await axios.get(url);

        if (response.data.data) {
          // console.log(response.data.data);
          let message = JSON.parse(response.data.data);
          if (liff.isApiAvailable('shareTargetPicker')) {
            liff.shareTargetPicker([message], { isMultiple: true }).then(function (res) {
              if (res) {
                console.log('sent');
              } else {
                console.log('user canceled sharing.');
              }
            }).catch(function (error) {
              console.error(error);
            });
          } else {
            alert('此 LINE 帳號尚未開啟 Share Target Picker 功能');
            console.log('share target picker api not available.');
          }
        } else {
          throw false
        }
      } catch (e) {
        alert('分享團購失敗');
        console.error(e);
      }
    },

    handleTogroupBuying() {
      this.$emit("chooseGroupBuying", this.groupBuying.id);
    },
    handleSlide() {
      // activeIndex 在 loop 時會有 bug，所以改用 realIndex
      // this.activeIndex = this.swiper.activeIndex;
      this.activeIndex = this.swiper.realIndex + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-swiper-initialized {
  padding: 0;
  .swiper-slide {
    width: 100%;
  }
  ::v-deep .product-swiper-button-prev,
  ::v-deep .product-swiper-button-next {
    background: #2c2c2e;
    opacity: 0.8;
    border-radius: 40px;
  }
}
.products-card {
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 4px 0px #0000001a;
  border-radius: 10px;
  background: #fff;
  overflow-y: auto;
  height: calc(100dvh - 20px);
  display: flex;
  flex-direction: column;
}

.card__head {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
}

.card__body {
  padding: 20px 12px;
  flex: 1;
  .has-status & {
    flex: none;
  }
}

.card__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #2c2c2e;
  margin-bottom: 20px;
}

.card__desc {
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #636366;

  ::v-deep li {
    position: relative;
    padding-left: 16px;
  }
  ::v-deep li:before {
    content: "-";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    /* width: 8px;
    height: 1px; */
    color: #3973ac;
  }
}

.card__footer {
  padding: 16px 20px;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px -4px 8px;
}

.card__split-line {
  border-top: 2px dashed #e5e5ea;
}

.card__line {
  border-top: 2px dashed #e5e5ea;
  position: absolute;
  top: 0;
  left: 8px;
  right: 8px;
}

.card__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.408px;
  color: #2c2c2e;
  &:nth-child(2) {
    margin-bottom: 20px;
  }
}

.card__row--volume {
  color: var(--liff-button-color);
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.408px;
  margin-right: 4px;
}

.card__actions {
  display: flex;
  margin-top: 8px;
  button:nth-child(1) {
    margin-right: 10px;
  }
}

.page,
.product-swiper-button-prev,
.product-swiper-button-next {
  padding: 0 8px;
  background: #2c2c2e;
  opacity: 0.8;
  border-radius: 40px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 1;

  svg {
    fill: #fff;
    vertical-align: text-top;
  }
}

.page {
  right: auto;
  left: 12px;
  padding: 2px 10px;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
}

.product-swiper-button-prev {
  right: 64px;
}

.barcode {
  position: relative;
  text-align: center;
  .barcode__id {
    background: #f2f2f7;
    border-radius: 10px;
    padding: 15px;
  }
  .barcode__stamp {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(-25deg);
  }
}

</style>
