<template>
  <div>
    <div v-if="showLoading"><Loading /></div>
    <template v-if="isList">
      <swiper :space-between="50" :options="swiperOption">
        <swiper-slide
          v-for="groupBuying in groupBuyings"
          :key="groupBuying.id"
        >
          <ProductCard
            :isLoading="showLoading"
            :groupBuying="groupBuying"
            @chooseGroupBuying="chooseGroupBuying"
          ></ProductCard>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </template>
    <template v-else>
      <ProductCard
        v-if="groupBuyings[0]"
        :groupBuying="groupBuyings[0]"
        @chooseGroupBuying="chooseGroupBuying"
      ></ProductCard>
    </template>

    <div class="empty-list" v-if="groupBuyings.length == 0 && isLoaded">
      <svg
        width="200"
        height="100"
        viewBox="0 0 200 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M92.7059 17V39.9412H43V82H157.706V55.2353H150.059V17H92.7059ZM94.6176 18.9118H106.088V38.7198L113.735 34.351L121.382 38.7198V18.9118H132.853V55.2353H94.6176V18.9118ZM108 18.9118H119.471V35.4272L113.735 32.149L108 35.4272V18.9118ZM134.765 18.9118H148.147V55.2353H134.765V18.9118ZM44.9118 41.8529H56.3824V59.7492L64.0294 55.3804L71.6765 59.7492V41.8529H83.1471V80.0882H44.9118V41.8529ZM58.2941 41.8529H69.7647V56.4566L64.0294 53.1785L58.2941 56.4566V41.8529ZM85.0588 41.8529H92.7059V55.2353H85.0588V41.8529ZM85.0588 57.1471H107.043V80.0882H85.0588V57.1471ZM108.957 57.1471H116.604V80.0882H108.957V57.1471ZM118.516 57.1471H140.5V80.0882H118.516V57.1471ZM142.414 57.1471H155.796V80.0882H142.414V57.1471Z"
          fill="#C0C4CC"
        />
      </svg>
      <div>沒有團購商品</div>
    </div>

    <Popup title="確認下單" ref="popupChooseGroupBuying">
      <template v-if="chosenGroupBuying.need_choose_branch">
        <div class="s-space-x-2 s-flex mb-2">
          <SharedSelect
            :value="city"
            @input="handleCityChange"
            :options="cityOptions"
            placeholder="請選擇縣市"
          />
          <SharedSelect
            :value="district"
            @input="handleDistrictChange"
            :options="districtOptions"
            placeholder="請選擇區"
          />
        </div>
        <div class="s-mb-5">
          <SharedSelect
            v-model="branch"
            :options="branchOptions"
            placeholder="請選擇分店"
          />
        </div>
      </template>

      <div class="s-mt-5 s-mb-5 s-text-left">
        {{ chosenGroupBuying.order_message }}
        <!-- 團購成團後您將可領取 -->
        <!-- <span class="s-text-primary s-font-medium">$200</span> 折價券 -->
      </div>
      <div slot="actions" class="s-flex s-space-x-2">
        <button
          class="s-btn s-liff-theme-btn-outline s-btn-sm"
          type="button"
          @click="$refs.popupChooseGroupBuying.close()"
        >
          取消
        </button>
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          @click="handleChoosegroupBuyingstore"
        >
          {{ showLoading ? '處理中...' : '確定加入' }}
        </button>
      </div>
    </Popup>

    <Popup title="下單完成" ref="popupJoinSuccess">
      <div class="s-mt-5 s-mb-5">
        {{ chosenGroupBuying.success_message }}
        <!-- 團購成團後您將可領取 -->
        <!-- <span class="s-text-primary s-font-medium">$200</span> 折價券 -->
      </div>
      <div slot="actions" class="s-flex s-space-x-2">
        <button
          class="s-btn s-btn-outline-primary s-btn-sm"
          type="button"
          @click="goOrderList"
        >
          團購紀錄
        </button>
        <button
          class="s-btn s-btn-outline-primary s-btn-sm"
          type="button"
          @click="goCoreGroupbuyingList"
        >
          回團購商品頁
        </button>
      </div>
    </Popup>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Popup from "@/components/Page/Liff/Shared/Popup";
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import ProductCard from "@/components/Page/Liff/GroupBuying/Products/ProductsCard.vue";
import Loading from '@/components/Page/Liff/Shared/Loading'
import CoreGroupBuyingApi from "@/apis/liff/CoreGroupBuying";

export default {
  components: {
    ProductCard,
    Swiper,
    SwiperSlide,
    Popup,
    SharedSelect,
    Loading,
  },
  data() {
    return {
      showLoading: true,
      city: null,
      district: null,
      branch: null,
      swiperOption: {
        spaceBetween: 10,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      cityOptions: [],
      districtOptions: [],
      branchOptions: [],
      options: [{ text: "台北市", value: "taipei" }],
      groupBuyings: [],
      chosenGroupBuying: {
        need_choose_branch: false,
        order_message: null,
        success_message: null,
      },
      isLoaded: false,
    };
  },
  computed: {
    isList() {
      return this.$route.name === "LiffCoreGroupBuyingList";
    },
  },
  async mounted() {
    // this.$refs.popupJoinSuccess.open()
    if (this.isList) {
      await this.fetchgroupBuyings();
    } else {
      await this.fetchgroupBuying();
    }
    this.fetchCityOptions();
    this.isLoaded = true
    this.showLoading = false
  },
  methods: {
    async fetchgroupBuyings() {
      this.groupBuyings =
        await CoreGroupBuyingApi.getInstance().getGroupBuyings();
    },
    async fetchgroupBuying() {
      const groupBuying = await CoreGroupBuyingApi.getInstance().getGroupBuying(
        { id: this.$route.params.id }
      );
      this.groupBuyings = [groupBuying];
    },
    handleCityChange(value) {
      this.city = value;
      this.fecthDistrictOptions();
      this.district = null;
      this.branch = null;
      this.branchOptions = [];
    },
    handleDistrictChange(value) {
      this.district = value;
      this.fetchBranchOptions();
    },
    async fetchCityOptions() {
      const cities = await CoreGroupBuyingApi.getInstance().getBranchCities();
      this.cityOptions = cities.map((city) => {
        return {
          text: city,
          value: city,
        };
      });
    },
    async fecthDistrictOptions() {
      if (!this.city) {
        return;
      }

      const districts =
        await CoreGroupBuyingApi.getInstance().getBranchDistricts({
          city: this.city,
        });
      this.districtOptions = districts.map((district) => {
        return {
          text: district,
          value: district,
        };
      });
    },
    async fetchBranchOptions() {
      if (!this.city || !this.district) {
        return;
      }

      const branches = await CoreGroupBuyingApi.getInstance().getBranches({
        city: this.city,
        district: this.district,
      });
      this.branchOptions = branches.map((branch) => {
        return {
          text: branch.name,
          value: branch.id,
        };
      });
    },
    chooseGroupBuying(groupBuyingId) {
      this.$refs.popupChooseGroupBuying.open();
      this.chosenGroupBuying = this.groupBuyings.find(
        (groupBuying) => groupBuying.id == groupBuyingId
      );
    },
    async handleChoosegroupBuyingstore() {
      // prevent double clikc
      if (this.showLoading) {
        return
      }

      this.showLoading = true
      await this.doHandleChoosegroupBuyingstore()
      this.showLoading = false
    },
    async doHandleChoosegroupBuyingstore() {
      try {
        // call api
        const groupBuyingOrder = await CoreGroupBuyingApi.getInstance().createOrder({
          id: this.chosenGroupBuying.id,
          skuId: this.chosenGroupBuying.sku.id,
          quantity: 1,
        });
        this.chosenGroupBuying.current_quantity = groupBuyingOrder.groupbuying.current_quantity
        this.$refs.popupChooseGroupBuying.close();
        this.$refs.popupJoinSuccess.open();
      } catch (error) {
        let message = "";
        if (error.response?.message) {
          message = error.response.message;
        }
        this.$swal("團購失敗", message, "error");
      }
    },
    goOrderList() {
      this.$router.push({ name: "LiffCoreGroupBuyingOrderProcessingList" });
    },
    goCoreGroupbuyingList() {
      window.location.reload()
    },
  },
};
</script>

<style lang="scss">
.swiper-container {
  padding: 10px 20px;
}
.swiper-button-prev,
.swiper-button-next {
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 4px 0px #00000026;
  border-radius: 50%;
  background: #fff;
  width: 32px;
  height: 32px;
  /* calc(image-height + image-padding-top + button-size / 2 + offset) */
  /* image-height = screen-width - image-padding-x */
  top: min(calc((100vw - 20px * 2) + 10px + 32px / 2 + 40px), calc(100dvh - 100px));
  &:after {
    color: #000000;
    font-size: 16px;
  }
}
.swiper-button-prev:after {
  padding-right: 2px;
}
.swiper-button-next:after {
  padding-left: 2px;
}

.empty-list {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #636366;
}
</style>
